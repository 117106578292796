import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import GeneralStats from "./GeneralStats";
import { Box, Tab } from "@mui/material";
import AiStats from "./AiStats/AiStats";
import OtherStats from "./OtherStats/OtherStats";
import { useTranslation } from "react-i18next";

function Statistics() {
  const [value, setValue] = React.useState("1");
  const { t } = useTranslation();
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {" "}
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ sx: { backgroundColor: "#6932F9" } }}
            >
              <Tab
                label={t("General Stats")}
                value="1"
                sx={{ color: value === "1" && "#6932F9 !important" }}
              />
              <Tab
                label={t("Ai Stats")}
                value="2"
                sx={{ color: value === "2" && "#6932F9 !important" }}
              />
              <Tab
                label={t("Session Stats")}
                value="3"
                sx={{ color: value === "3" && "#6932F9 !important" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: 0 }}>
            {" "}
            <GeneralStats />
          </TabPanel>
          <TabPanel value="2" sx={{ p: 0 }}>
            <AiStats />
          </TabPanel>
          <TabPanel value="3" sx={{ p: 0 }}>
            <OtherStats />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default Statistics;
